export function Experience({ name, position, descriptions, dates, link }) {
  return (
    <div className="experience">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <div>
          <h2 className="underlined">
            <span>{position}</span>
          </h2>
        </div>
        <div><h3 style={{ fontWeight: 'light' }}>{name}</h3></div>
        <div><p style={{ color: 'var(--secondary)' }}>{dates}</p></div>
        {descriptions.length > 1 ? (
          <ul>
            {descriptions.map((description, index) => (
              <li key={index}>{description}</li>
            ))}
          </ul>
        ) : (
          <p>{descriptions}</p>
        )}
      </a>
    </div>
  );
}

export function Project({ name, description, link }) {
  return (
    <div className="experience project">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <div>
          <div><h2 className='underlined'><span>{name}</span></h2></div>
        </div>
        <p>{description}</p>
      </a>
    </div>
  );
}
