import React, { useState, useEffect } from 'react';
import { SocialIcon } from 'react-social-icons';
import { Experience, Project } from './components';

import './App.css';

// TODO: make the links buttons?, add little skill tags for each experience

function App() {
  const alertThreshold = 8;
  const clickInterval = 500;
  const colors = ['#c1baba', '#ccc9c0', '#bfc9b8', '#c8b5a5', '#b9c6be', '#a4a4bf'];
  const currentYear = new Date().getFullYear();

  const [isLightMode, setIsLightMode] = useState(false);
  const [hasDiscoAlertShown, setHasDiscoAlertShown] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  let lightModeStyle = null;

  const handleThemeToggle = () => {
    if (!isLightMode) {
      const randomColor = colors[Math.floor(Math.random() * colors.length)];

      // Check that the color is not the same as the current color
      if (lightModeStyle && lightModeStyle.innerHTML.includes(randomColor)) {
        handleThemeToggle();
        return;
      }

      if(!lightModeStyle) {
        lightModeStyle = document.createElement('style');
        document.head.appendChild(lightModeStyle);
      }

      // TODO: Think about collapsing the light and dark mode CSS variables into one and just changing the values programmatically
      lightModeStyle.innerHTML = `
        .light-mode {
          --bg: ${randomColor};
          --primary: #07080a;
          --secondary: #333333;
        }
      `;
    } else {
      if (lightModeStyle) {
        lightModeStyle.parentNode.removeChild(lightModeStyle);
        lightModeStyle = null;
      }
    }

    document.getElementsByTagName('body')[0].classList.toggle('light-mode');
    setIsLightMode(!isLightMode);

    // Adding the alert as a safety measure
    if (!hasDiscoAlertShown) setClickCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    if (clickCount >= alertThreshold) {
      alert("Disco Partyyyy! 🪩");
      setClickCount(0);
      setHasDiscoAlertShown(true);
    }
    
    const timer = setTimeout(() => setClickCount(0), clickInterval);
    
    return () => clearTimeout(timer);
  }, [clickCount]);

  return (
    <div className="App">
      <div className="section" id="welcome">
        <div>
          <h1>Hi, I'm Parth 👋</h1>
          <p className="heavy-p">Software Engineer</p>
        </div>
        <div className='mystery-button' id='mystery-button-1'>
          <button onClick={handleThemeToggle} className='link-button'>
            {isLightMode ? <p>I'm feeling lucky 🌞</p> : <p>I'm feeling lucky 🌙</p>}
          </button>
        </div>
        <div id="socials">
          <p>Get in touch</p>
          <div>
            <SocialIcon url='mailto:hi@parthparth.com' bgColor='var(--bg)' fgColor='var(--primary)' target='_blank' rel='noopener noreferrer' />
            <SocialIcon url='https://github.com/thecodingaviator' bgColor='var(--bg)' fgColor='var(--primary)' target='_blank' rel='noopener noreferrer' />
            <SocialIcon url='https://www.linkedin.com/in/hiparthparth/' bgColor='var(--bg)' fgColor='var(--primary)' target='_blank' rel='noopener noreferrer' />
            <SocialIcon url='https://codepen.io/thecodingaviator' bgColor='var(--bg)' fgColor='var(--primary)' target='_blank' rel='noopener noreferrer' />
          </div>
        </div>
      </div>
      <div className="section" id="information">
        <p id="introduction">
          I have been developing web apps since 2019 when I was first introduced to <a className="custom-link" href="https://web.archive.org/web/20240829013059/https://opensource.com/resources/what-open-source">Open Source Software</a> through Hacktoberfest.
          My journey started with the <a href="https://github.com/freeCodeCamp/freeCodeCamp">freeCodeCamp</a> project, where I went on to become a project moderator, interacting with a community of over 4,000 members.
          Since then, I have worked on all kinds of stuff from data dashboards and program websites to full web apps for companies (...more below).
          <br /><br />
          Some of the technologies I have worked with includes React, Express, and TypeScript. Besides those, I have worked with Python, Node, and mySQL on the backend. I'm learning <a href="https://www.prisma.io/">Prisma</a> lately and I'm excited to see where it takes me.
          <br /><br />
          In my free time, I like to contribute to open-source projects, write browser extensions to make life easier, and learn new technologies. When I'm not around a computer, I'm either <a href="https://wmhbradio.org/">DJing for a radio show 📻</a>, working on my pilot's license ✈️, or <a href="https://www.alltrails.com/explore/list/my-favourite-hikes-0332d69">out and about ⛰️</a>.
        </p>
        <h2 className="title">Experience</h2>
        <Experience
          position="Software Engineering Intern"
          name="Authentic Insurance"
          descriptions={[
            <p>Added tracking features aiming at user retention for specific clients.</p>,
            <p>Added self-servicing features, reducing customer service costs on 100% of our clients.</p>,
            <p>Worked with the business development team to reduce user friction on the website.</p>
          ]}
          dates="Jun 2023 - Aug 2023"
          link="https://authenticinsurance.com"
        />
        <Experience
          position="Open Source Dev + Maintainer"
          name="freeCodeCamp"
          descriptions={[
            <p>Contributed 13,000+ lines of code and moderated the GitHub repo, overseeing organic growth from 250k to 334k stars, interacting with <a href="https://github.com/freeCodeCamp/freeCodeCamp/issues?q=commenter%3Athecodingaviator+">1,700+ contributions</a>.</p>,
            <p>Co-led site-wide migration to TypeScript for the online platform with ~2.5M views a month.</p>,
            <p>Mentored a community of over 4,300 people, reviewing and adding contributions to the project, taking it to 27k forks.</p>
          ]}
          dates="Mar 2019 - May 2023"
          link="https://github.com/freeCodeCamp/freeCodeCamp"
        />
        <Experience
          position="Fullstack Web Developer"
          name="Bigelow Lab for Ocean Sciences"
          descriptions={[
            <p>Designed and implemented a web dashboard, boosting project collaboration amongst 30+ researchers and staff at 10+ institutions.</p>,
            <p>Leveraged Google Drive and DigitalOcean Droplets to implement file sharing, improving efficiency by 50%.</p>,
            <p>Developed a robust data UI, streamlining and standardizing data sharing amongst researchers from varied academic disciplines.</p>
          ]}
          dates="May 2023 - Dec 2023"
          link="https://www.bigelow.org/"
        />
        <div className='mystery-button' id='mystery-button-2'>
          <button onClick={handleThemeToggle} className='link-button'>
            {isLightMode ? <p>I'm feeling lucky 🌞</p> : <p>I'm feeling lucky 🌙</p>}
          </button>
        </div>
        <h2 className="title">Education</h2>
        <Experience
          position="Bachelor of Arts, Colby College"
          name="Double Majoring in CS and Economics"
          descriptions={[
            <p>Presidential Scholar, Dean's List, Colby Student Government, Colby Outing Club</p>
          ]}
          dates="Sep 2021 - May 2025"
        />
        <h2 className="title">Projects</h2>
        <Project name="Passgen Extension"
          description={<p>I switched from Chrome to Brave recently, but I miss the password generator that Chrome has built-in. So, instead of using a third-party extension, I built my own.</p>}
          link="https://github.com/thecodingaviator/passgen"
        />
        <Project name="URL Meta Scraper"
          description={<p>There were no JS tools to scrape metadata from a URL, so I created an Express REST API to do just that. There are PHP and Python solutions, but I did not want to add that complexity.</p>}
          link="https://github.com/thecodingaviator/get-url-meta"
        />
        <Project name="Arduino Alarm Beacon"
          description={<p>Over my internship at Authentic Insurance, I built a simple beacon alarm system using an Arduino microcontroller and a beacon 🚨 that goes off whenever a policy is sold.</p>}
          link="https://github.com/thecodingaviator/authentic-policy-alarm"
        />
        <footer>
          <p>Made with <span style={{ color: 'red' }}>❤️</span> by <a href="https://github.com/thecodingaviator">Parth</a>. © {currentYear}</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
